import { URLSearchParams } from '@angular/http';
import { VisitaPrevia } from './../../model/visitaPrevia.model';

import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';

import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';
import { Injectable } from '@angular/core';

export class VisitaPreviaFiltro {
  situacaoVisita: string;
  status: string;
  usuarioCadastro:number;
  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class VisitaPreviaService {

  url: string;

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.url = `${environment.apiUrl}/visita-previa`;
  }

  pesquisar(filtro: VisitaPreviaFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.situacaoVisita) {
      params.set('situacaoVisita', filtro.situacaoVisita);
    }
    if (filtro.status) {
      params.set('status', filtro.status);
    }
    if (filtro.usuarioCadastro) {
      params.set('usuarioCadastro', filtro.usuarioCadastro.toString());
    }
    
    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        return responseJson;
      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: VisitaPrevia): Promise<VisitaPrevia> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: VisitaPrevia): Promise<VisitaPrevia> {

    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as VisitaPrevia);
  }

  buscarPorCodigo(codigo: number): Promise<VisitaPrevia> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as VisitaPrevia;
        return entidade;
      });
  }

}
